exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-art-apple-style-wallpaper-tsx": () => import("./../../../src/pages/art/apple-style-wallpaper.tsx" /* webpackChunkName: "component---src-pages-art-apple-style-wallpaper-tsx" */),
  "component---src-pages-art-arcs-tsx": () => import("./../../../src/pages/art/arcs.tsx" /* webpackChunkName: "component---src-pages-art-arcs-tsx" */),
  "component---src-pages-art-bezier-tsx": () => import("./../../../src/pages/art/bezier.tsx" /* webpackChunkName: "component---src-pages-art-bezier-tsx" */),
  "component---src-pages-art-canvas-tsx": () => import("./../../../src/pages/art/Canvas.tsx" /* webpackChunkName: "component---src-pages-art-canvas-tsx" */),
  "component---src-pages-art-exploring-techniques-for-meridianlike-generative-art-tsx": () => import("./../../../src/pages/art/exploring-techniques-for-meridianlike-generative-art.tsx" /* webpackChunkName: "component---src-pages-art-exploring-techniques-for-meridianlike-generative-art-tsx" */),
  "component---src-pages-art-generative-icon-design-a-solandra-tutorial-tsx": () => import("./../../../src/pages/art/generative-icon-design-a-solandra-tutorial.tsx" /* webpackChunkName: "component---src-pages-art-generative-icon-design-a-solandra-tutorial-tsx" */),
  "component---src-pages-art-index-tsx": () => import("./../../../src/pages/art/index.tsx" /* webpackChunkName: "component---src-pages-art-index-tsx" */),
  "component---src-pages-art-kotlin-first-steps-tsx": () => import("./../../../src/pages/art/kotlin-first-steps.tsx" /* webpackChunkName: "component---src-pages-art-kotlin-first-steps-tsx" */),
  "component---src-pages-art-solving-sol-with-solandra-tsx": () => import("./../../../src/pages/art/solving-sol-with-solandra.tsx" /* webpackChunkName: "component---src-pages-art-solving-sol-with-solandra-tsx" */),
  "component---src-pages-art-watercolour-tsx": () => import("./../../../src/pages/art/watercolour.tsx" /* webpackChunkName: "component---src-pages-art-watercolour-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-ideas-index-tsx": () => import("./../../../src/pages/ideas/index.tsx" /* webpackChunkName: "component---src-pages-ideas-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legacy-projects-tsx": () => import("./../../../src/pages/legacy-projects.tsx" /* webpackChunkName: "component---src-pages-legacy-projects-tsx" */),
  "component---src-pages-long-form-tsx": () => import("./../../../src/pages/long-form.tsx" /* webpackChunkName: "component---src-pages-long-form-tsx" */),
  "component---src-pages-projects-chromatic-curves-index-tsx": () => import("./../../../src/pages/projects/chromatic-curves/index.tsx" /* webpackChunkName: "component---src-pages-projects-chromatic-curves-index-tsx" */),
  "component---src-pages-projects-coder-flash-cards-editor-tsx": () => import("./../../../src/pages/projects/coder-flash-cards/editor.tsx" /* webpackChunkName: "component---src-pages-projects-coder-flash-cards-editor-tsx" */),
  "component---src-pages-projects-coder-flash-cards-index-tsx": () => import("./../../../src/pages/projects/coder-flash-cards/index.tsx" /* webpackChunkName: "component---src-pages-projects-coder-flash-cards-index-tsx" */),
  "component---src-pages-projects-coder-flash-cards-spec-tsx": () => import("./../../../src/pages/projects/coder-flash-cards/spec.tsx" /* webpackChunkName: "component---src-pages-projects-coder-flash-cards-spec-tsx" */),
  "component---src-pages-projects-icon-generator-index-tsx": () => import("./../../../src/pages/projects/icon-generator/index.tsx" /* webpackChunkName: "component---src-pages-projects-icon-generator-index-tsx" */),
  "component---src-pages-projects-index-tsx": () => import("./../../../src/pages/projects/index.tsx" /* webpackChunkName: "component---src-pages-projects-index-tsx" */),
  "component---src-pages-projects-micro-reviews-index-tsx": () => import("./../../../src/pages/projects/micro-reviews/index.tsx" /* webpackChunkName: "component---src-pages-projects-micro-reviews-index-tsx" */),
  "component---src-pages-projects-rhythm-pro-index-tsx": () => import("./../../../src/pages/projects/rhythm-pro/index.tsx" /* webpackChunkName: "component---src-pages-projects-rhythm-pro-index-tsx" */),
  "component---src-pages-projects-transformable-index-tsx": () => import("./../../../src/pages/projects/transformable/index.tsx" /* webpackChunkName: "component---src-pages-projects-transformable-index-tsx" */),
  "component---src-pages-reviews-tsx": () => import("./../../../src/pages/reviews.tsx" /* webpackChunkName: "component---src-pages-reviews-tsx" */),
  "component---src-pages-updates-tsx": () => import("./../../../src/pages/updates.tsx" /* webpackChunkName: "component---src-pages-updates-tsx" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-review-tsx": () => import("./../../../src/templates/review.tsx" /* webpackChunkName: "component---src-templates-review-tsx" */),
  "component---src-templates-update-js": () => import("./../../../src/templates/update.js" /* webpackChunkName: "component---src-templates-update-js" */)
}

